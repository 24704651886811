import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'webcoffee-alert-icon',
    templateUrl: './alert-icon.component.html',
    styleUrls: ['./alert-icon.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AlertIconComponent {}

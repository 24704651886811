<div id="confirmation-dialog-wrapper">
    <p class="message my-3" *ngIf="confirmed === undefined">{{ data.message }}</p>
    <p class="message my-3" *ngIf="confirmed === true">Operatiune confirmata</p>
    <p class="message my-3" *ngIf="confirmed === false">Operatiune anulata</p>
    <webcoffee-alert-icon *ngIf="confirmed === true" success></webcoffee-alert-icon>
    <webcoffee-alert-icon *ngIf="confirmed === false" error></webcoffee-alert-icon>
    <webcoffee-alert-icon *ngIf="confirmed === undefined" warning></webcoffee-alert-icon>
    <div class="confirmation-dialog-button-group w-100 my-3">
        <button mat-raised-button class="mx-2" color="warn" (click)="closeDialog(true)">Da</button>
        <button mat-raised-button class="mx-2" (click)="closeDialog(false)">Anuleaza</button>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AlertIconComponent } from '../alert-icon/alert-icon.component';

@Component({
    selector: 'webcoffee-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatButtonModule, AlertIconComponent, MatDialogModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}
    confirmed!: boolean;

    closeDialog(confirmed: boolean): void {
        this.confirmed = confirmed;
        setTimeout(() => {
            this.dialogRef.close({ confirmed });
        }, 1000);
    }
}
